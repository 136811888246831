@import "@/assets/scss/style.scss";

.wrapperTwoRowWithTag {
    display: flex;
    flex-direction: column;

    .topRow {
        color: $grey-7;
        margin-bottom: 13px;
        @extend .u-b2--;
    }

    .bottomRow {
        margin-bottom: 2px;
        color: $grey-9;
        @extend .u-b2;

        &--link {
            color: $blue-6;
        }
    }

    &.isLight {
        .bottomRow {
            color: $grey-8;
        }
    }

    &.isLarge {
        .bottomRow {
            @extend .u-b0-;
        }
    }

    &.isSmallGap {
        .topRow {
            margin-bottom: 4px;
        }
    }
}

.offerBottomBar__description .bottomRow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
}
